<template>
  <div>
    <Carousel
      class="pb-8"
      :carousel-id="carouselId"
      :api-options="apiOptions"
      :title-options="titleOptions"
      :carousel-options="carouselOptions"
      :floater-options="{
        disabled: true,
        onClick: onFloaterClick,
      }"
      disable-floater
      carousel-type="standard_thumbnail"
    >
      <template #title>
        <div class="flex items-center gap-x-4 px-6 md:px-11 lg:px-[45px]">
          <h2
            class="text-title-medium md:text-title-big lg:text-headline-small text-white opacity-[0.87]"
          >
            {{ titleOptions.label }}
          </h2>
        </div>
      </template>
      <template #slider-item="{ item, key }">
        <slot name="sliderItem" :item="item" :key="key"></slot>
      </template>
    </Carousel>
  </div>
</template>

<script setup>
const route = useRoute();
const { t } = useI18n();
const carouselOptions = computed(() => {
  return {
    breakpoints: {
      375: {
        spaceBetween: 8,
        slidesOffsetBefore: 24,
      },
      768: {
        spaceBetween: 16,
        slidesOffsetBefore: 44,
      },
      1024: {
        spaceBetween: 24,
        slidesOffsetBefore: 45,
        slidesOffsetAfter: 45,
      },
      1025: {
        spaceBetween: 24,
        slidesOffsetBefore: 45,
        slidesOffsetAfter: 45,
      },
    },
  };
});

const identifier = computed(() => {
  return route.params.seriesId || route.params.movieId;
});

const carouselId = computed(() => {
  const key = identifier.value || route.query.media;
  return `similar-${key}`;
});

const titleOptions = computed(() => {
  return { label: t("Similar to this") };
});

const onFloaterClick = (carouselItem) => {
  const query = {
    ...carouselItem,
    assetExternalId: carouselItem.externalId,
    externalContentId: carouselItem.externalId,
    externalSeriesId: carouselItem.tvShowSeries?.externalId,
    contentType: carouselItem.contentType,
    type: carouselItem.type,
  };
  return useInfoClickEvent(query, { replace: true });
};

// TODO: fix padding for similar to this carousel
const apiOptions = computed(() => {
  let contentType = "vod";
  let external_id =
    route.params.movieId ||
    route.query.assetExternalId ||
    route.query.video_external_id;

  const isShowInParams = "seriesId" in route.params;

  if (isShowInParams || ["Series", "Season"].includes(route.query.type)) {
    contentType = "series";
    external_id = route.params.seriesId || route.query.externalSeriesId;
  }

  return {
    path: "/biz/recommendation/similar",
    query: {
      external_id,
      content_type: contentType,
      from: 0,
      count: 20,
    },
  };
});
</script>

<style lang="scss" scoped></style>
